.general-information-form,
.personal-information-form {
  .form-row {
    display: flex;
    gap: 12px;

    &:not(:first-of-type) {
      margin-top: 12px;
    }

    .first-name-input {
      width: 200px;
    }
  }
}

.middle-name-input {
  width: 130px;
}

.license-number-input {
  width: 160px;
}

.buttons-container {
  margin-top: 80px;
}

.next-button {
  width: 100px;
}
