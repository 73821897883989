#manage-driving-schools-card {
  .manage-schools-buttons {
    width: 200px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    gap: 12px;
    margin: 0px auto;
  }
}
