#ds-profile-personnels {
  .options-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .personnels-table {
    margin-top: 12px;
    width: 600px;
  }
}
