#about-company-card {
  background-color: #ffffff;
  --font-size: 1.125rem;
  padding: 3rem;

  .coso-logo {
    width: 50%;
    max-width: 250px;
    font-family: Inter-Bold;
  }

  .main-header-text {
    margin: 3rem 0rem;
  }

  li {
    font-size: var(--font-size);

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  p {
    font-size: var(--font-size);
    line-height: 2rem;
    text-align: justify;
  }

  .bp3-divider {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  section:not(:first-child) {
    margin-top: 2rem;
  }

  .container-buffer {
    margin-right: 30%;
  }

  .column-divider {
    display: grid;
    grid-template-columns: 60% 40%;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .our-process-img {
    width: 100%;
  }

  .green {
    color: rgb(8, 186, 25);
  }

  .yellow {
    color: rgb(250, 192, 27);
  }

  .red {
    color: rgb(233, 38, 25);
  }

  .blue {
    color: rgb(28, 149, 252);
  }

  .purple {
    color: rgb(88, 54, 239);
  }

  .blue-green {
    color: rgb(28, 197, 172);
  }

  .bigger-font {
    font-size: 1.25rem;
  }

  .center-container {
    display: flex;
    justify-content: center;
  }

  .imri-container {
    .imri-img {
      width: 45%;
      max-width: 250px;
      margin: 0rem auto;
      text-align: center;
      margin-bottom: 3rem;
    }

    .red {
      color: rgb(234, 59, 92);
    }

    .yellow {
      color: rgb(255, 169, 105);
    }

    .green {
      color: rgb(61, 173, 146);
    }

    .purple {
      color: rgb(143, 16, 103);
    }
  }
}
