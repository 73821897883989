.table-checkbox {
  margin-bottom: 0px;
}

.nested-checkbox {
  margin-left: 24px;
}

.dl-license-classification-table {
  border-collapse: collapse;
  max-width: 1000px;
  width: 60vw;
  
  th,
  td {
    padding: 10px;

    &:first-child {
      padding-right: 40px;
    }

    &.table-border-top {
      border-top: 1px solid var(--black);
    }

    &.table-border-right {
      border-right: 1px solid var(--black);
    }

    &.table-border-bottom {
      border-bottom: 1px solid var(--black);
    }

    &.table-border-left {
      border-left: 1px solid var(--black);
    }
  }

  .details-cell {
    vertical-align: middle;
  }

  .radio-button-cell {
    text-align: center;
    width: 90px;
  }

  .standalone-radio-button {
    margin: 0px;
    padding-left: 35px !important;
  }

  .dl-classification-buttons-container {
    margin-top: 80px;
  }
}
