.about-page-container {
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
  position: fixed;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  overflow: auto;
}

#cards-container.about-cards-container {
  z-index: 1;
  font-size: 1.25rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
