table {
  &.styled-table {
    border-collapse: collapse;

    & > tr {
      &:nth-child(2n) {
        & > td {
          background-color: #f4f4f4;
        }
      }

      &:nth-child(2n + 1) {
        & > td {
          background-color: #dadada;
        }
      }

      & > th {
        background-color: #afafaf;
        height: 40px;
      }

      & > td {
        height: 44px;
        white-space: pre;
      
        &.center-cell {
          text-align: center;
        }

        &.spinner-container {
          padding: 80px;
        }

        &.no-data-cell {
          padding: 20px;

          .message-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .message {
              font-size: 20px;
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}

.pagination {
  margin: 40px 0px 0px 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  list-style: none;
  font-size: 20px;
  align-items: center;
  gap: 12px;

  .disabled {
    cursor: not-allowed !important;
    opacity: 0.3;
    outline-style: none;

    & > * {
      pointer-events: none;
    }
  }

  .selected {
    font-weight: 700;
  }

  & > * {
    margin: 0px;

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
