.custom-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
  gap: 20px;
}

.custom-card-header-text {
  margin: 0px;
  width: 450px;
}

.custom-card-divider {
  margin: 0px 0px 20px 0px;
}
