#settings-card {
  width: 450px;

  .field-container-hor:not(:first-child) {
    margin-top: 18px;
  }

  .change-password-button {
    margin-top: 40px;
  }
}
