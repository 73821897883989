.fingerprint-window {
  .fingerprint-icon-container {
    display: flex;
    justify-content: center;
    position: relative;

    .icon:not(:first-child) {
      position: absolute;
      
      &.pulse {
        animation: pulse 0.2s;
        @keyframes pulse {
          0% {
            transform: scale(0.95);
            opacity: 0.5;
          }

          100% {
            transform: scale(1.6);
            opacity: 0;
          }
        }
      }
    }
  }

  .message {
    margin-top: 30px;
    text-align: center;

    &.success-message {
      font-weight: 500;
    }
  }
}
