.change-password-window {
  .password-form {
    margin: 20px;

    .field-container-vert {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .buttons-container {
      margin-top: 40px;
    }
  }
}

.change-password-confirmation-window {
  .buttons-container {
    margin-top: 40px;
  }
}
