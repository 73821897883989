.student-profile {
  display: flex;
  justify-content: space-between;

  .form-row {
    &:not(:first-child) {
      margin-top: 1rem;
    }

    .field-container-vert:not(:first-child) {
      margin-left: 0.75rem;
    }
  }
}

.student-photo-container {
  position: relative;
  margin-left: 60px;
  margin-right: 30px;
  width: 150px;
  height: 150px;
  border: 2px solid var(--black);

  .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
}
