#page {
  padding: 40px;
}

#cards-container {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  gap: 2rem;
  width: 80%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

#main-navigation-bar {
  position: sticky;
  top: 0px;

  .menu {
    display: flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
    height: 100%;

    .menu-item {
      outline-style: none;
      font-weight: 600;
      border-radius: 0px;

      .link {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;

        &.active {
          background-color: #106ba3;
          fill: white;
          color: white;
        }

        .item-name {
          padding-left: 8px;
        }
      }
    }
  }

  .logo {
    height: 60%;
    margin-left: 2rem;
  }

  .nav-right {
    margin-right: 20px;

    .divider {
      margin: 0px 20px;
      height: 35px;
    }
  }
}
