#view-accounts-card {
  .search-form {
    padding: 40px;
    display: flex;
    gap: 12px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    width: 100%;
  }

  .view-accounts-table {
    min-width: 100%;
    margin-top: 20px;

    .ds-name-column {
      width: 300px;
    }

    .center-content {
      text-align: center;
    }
  }
}
