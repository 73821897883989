.course-details-page .form-row {
  display: flex;
  gap: 12px;
}

.course-details-form {
  .form-row {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
}

.horizontal-divider {
  margin: 40px 0px;
}

.forms-divider {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.forms-divider .vert-divider {
  border-right: 1px solid var(--black);
  width: 0px;
  height: 200px;
}

.subform .form-row:not(:first-child) {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

.radio-buttons-container {
  margin: 30px 0px;
}
