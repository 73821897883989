#recent-activities-card {
  .error-message-container {
    color: var(--red);
    fill: var(--red);
    text-align: center;
    
    .error-message {
      font-weight: 500;
    }
  }

  .table-container {
    max-height: 230px;
    overflow: auto;
    // margin-bottom: 20px;
    margin: 20px 15px;
    // margin-right: 20px;
    // margin-left: 20px;
    padding: 0px 10px;

    .notification-row {
      text-align: center;
    }
  }
}
