.home-page {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  gap: 20px;
}

.welcome-back-card {
  display: flex;
  align-items: center;
}

.text-container {
  margin-left: 24px;
}

.text-container h2 {
  margin: 0px 0px 10px 0px;
}

.field-container-hor:nth-child(n + 2) {
  margin-top: 8px;
}

.activities-table {
  margin-bottom: 12px;
}

.activities-table tr td {
  padding: 6px 0px;
}

.activities-table tr td:first-child {
  font-weight: 600;
  padding-right: 40px;
}
