.custom-select {
  cursor: pointer;
}

.custom-select * {
  pointer-events: none;
  user-select: none;
}

.custom-select-container .bp3-transition-container .bp3-popover-content{
  max-height: 250px;
  overflow-y: auto;
}
