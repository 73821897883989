#ds-profile-transactions {
  .options-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .transactions-table {
    margin-top: 12px;
    width: 600px;

    .descriptions-header {
      width: 250px;
    }
  }
}
