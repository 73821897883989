.instructions {
  font-size: 16px;
}

.email-form {
  margin-top: 40px;
}

.confirmation-text {
  padding-bottom: 30px;
  font-size: 16px;
}
