@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

html {
  width: 100%;
  height: 100%;
  background-color: #ebf1f5;
}

:root {
  --red: #9f3437;
  --black: #182026;
  --green: #11a159;
  --blue: rgb(5, 112, 192);
}


h1,
h2,
.highlight {
  color: var(--blue) !important;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

.bp3-toast-container {
  position: fixed !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.large-label {
  font-weight: 600;
  font-size: 16px;
}

.small-label {
  font-weight: 600;
}

.big-font {
  font-size: 16px;
}

.error-message {
  color: var(--red);
}

.unselect-text {
  user-select: none;
}

.field-container-hor {
  display: flex;
}

.field-container-hor > *:first-child {
  font-weight: 600;
  margin-right: 10px;
}

.field-container-vert > *:first-child {
  font-weight: 600;
  margin-bottom: 8px;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
