.registration-form {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 280px;
}

.text-input:not(:first-child),
.form-button {
  margin-top: 20px;
}

.error-message {
  margin-top: 18px;
  text-align: center;
}

.forgot-password {
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.information-buttons-container {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}