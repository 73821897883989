.information-text-card {
  padding: 4rem;
  
  .information-header {
    margin-bottom: 4rem;
  }

  .information-text {
    font-size: 1rem;
    white-space: pre-wrap;
    text-align: justify;
    line-height: 150%;
  }
}