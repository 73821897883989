.add-credits-form {
  display: grid;
  width: 400px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 20px;

  .payment-details {
    .field-container-vert:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.buttons-container {
  margin-top: 40px;
  grid-column-start: 1;
  grid-column-end: 3;
}
