.webcam-main-container {
  display: flex;
  gap: 60px;
  align-items: center;
}

.webcam-stream-container {
  position: relative;
  display: flex;
}

.webcamera-stream {
  width: 300px;
  object-fit: contain;
}

.webcam-spinner-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stream-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
}

.stream-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

.side-mask-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.upper-mask-container {
  width: 100%;
}

.stream-vert-divider {
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  height: 200px;
  /* align-self: flex-end; */
  width: 0px;
}

.webcam-form {
  text-align: center;
}

.picture-container {
  width: 150px;
  height: 150px;
}

.webcam-canvas {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.picture-container {
  position: relative;
}

.picture-placeholder {
  background-color: #cbcbcb;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.capture-button {
  margin-top: 25px;
}

.hidden-canvas {
  position: absolute;
  visibility: hidden;
}

.photo {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
