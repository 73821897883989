

.confirmation-card .form-row {
  display: flex;
  gap: 72px;
}

.confirmation-card .form-row:not(:first-child) {
  margin-top: 24px;
}

.confirmation-divider {
  margin: 40px 0px;
}

.confirmation-dl-license-classification-table {
  border-collapse: collapse;

  & > tr > td:not(:first-child) {
    text-align: center;
    padding: 0px 20px;
  }

  .nested-row {
    padding: 0px 40px;
    width: 430px;
    text-align: justify;
  }

}


.course-details-container {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  justify-content: space-evenly;
}

.confirmation-card .summary-container {
  margin-top: 30px;
}

.confirmation-card .subvalues {
  padding: 40px 60px;
  border: 1px solid var(--black);
}
