#ds-profile-issuing-branches {
  .options-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .issuing-branches-table {
    margin-top: 12px;
    width: 600px;

    .descriptions-header {
      width: 250px;
    }
  }

}

.add-issuing-branch-modal {
  .field-container-vert:not(:first-child) {
    margin-top: 24px;
  }
}
