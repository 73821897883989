.main-container {
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .form-container {
    border-radius: 3px;
    background-color: #ffffff;
    padding: 40px;
    width: 50vw;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
      width: 100%;
    }
  }

  .logos-container {
    display: flex;
    justify-content: space-around;

    .logo {
      object-fit: contain;
      min-width: 80px;
      width: 10vw;
      max-width: 100px;
      padding: 2px;
    }
  }

  .header-text {
    user-select: none;
  }
}
