.certificate-list-card {
  width: 50vw;
  
  .radio-group {
    margin-top: 40px;
    margin-left: 40px;

    & > *:not(:first-child) {
      margin-top: 13px;
    }
  }

  .search-container {
    display: flex;
    gap: 12px;
    justify-content: center;
    opacity: 0.3;
    transition: opacity 0.4s;

    &.show {
      opacity: 1;
    }
  }

  .form-row {
    display: flex;
    gap: 12px;
  }

  .date-picker-container {
    margin-left: 30px;
  }

  .buttons-container {
    margin-top: 35px;
  }

  .divider {
    margin: 30px 0px;
  }

  .table-container {
    overflow-x: scroll;
    padding-bottom: 40px;
  }

  .view-students-table {
    min-width: 100%;
    // max-width: 1600px;

    & > tr {
      & > td {
        padding: 8px 18px;

        &.date-column,
        &.status-column,
        &.license-number-column,
        &.control-number-column,
        &.issuing-branch-column,
        &.training-purpose-column,
        &.rating-column {
          white-space: nowrap;
        }

        &.actions-column {
          button:not(:first-child) {
            margin-left: 10px;
          }
        }

        .name-container {
          width: 200px;
        }
      }
    }
  }

  .pagination {
    position: sticky;
    left: 0px;
  }
}
